<template>
  <div class="row items-center">
    <div class="circle row justify-center">
      <p class="circle1">{{ user.name }}</p>
      <p class="circle2">{{ user.last_name }}</p>
      <q-tooltip anchor="bottom middle" self="top middle">
        {{ user.name }} {{ user.last_name }}
      </q-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {

  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },
    ...mapState("auth", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  background: var(--elements-color-primary);
  font-weight: 600;
}

.circle1 {
  font-size: 0;
}

.circle1:first-letter {
  font-size: 15px !important;
  color: var(--nav-color-primary) !important;
  text-transform: uppercase;
}

.circle2 {
  font-size: 0;
}

.circle2:first-letter {
  font-size: 15px !important;
  color: var(--nav-color-primary) !important;
  text-transform: uppercase;
}

.circle-text,
.q-tooltip {
  text-transform: capitalize;
}
</style>
